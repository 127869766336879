import React from "react";
import {useHistory, useLocation} from "react-router-dom";

const RedirectPage = () => {
  const location = useLocation();
  const history = useHistory();
  const idToken = new URLSearchParams(location.hash.substring(1)).get('id_token');
  if (idToken) {
    localStorage.setItem('id_token', idToken);
    history.replace('/home')
  }
  return (
    <div>
      <h3>
        Loading ....
      </h3>
    </div>
  )
}

export default RedirectPage;
