import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Wallets from "./pages/Wallets";
import Dashboard from "./pages/Dashboard";
import RedirectPage from "./pages/Loading";
import Sales from "./pages/Sales";
import Supplier from "./pages/Supplier";
import Transactions from "./pages/Transactions";
import Vat from "./pages/Vat";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";

const AuthCheck = () => {
  const history = useHistory();

  if (localStorage.getItem("id_token")) {
    history.replace("/home");
  }

  const redirectUri = process.env.REACT_APP_LOGIN_REDIRECT_URI;
  const loginRootUri = process.env.REACT_APP_LOGIN_ROOT_URI;
  const cognitoClientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const loginUri = `${loginRootUri}/login/?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`;

  return (
    <div>
      <div>
        <h1>Welcome to the dashboard!</h1>
      </div>
      <p>You are not logged in</p>
      <a href={loginUri}>Sign in</a>
    </div>
  );
};

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <main>
          <Switch>
            <Route exact path="/" component={AuthCheck} />
            <Route path="/sales">
              <Sales />
            </Route>
            <Route path="/supplier">
              <Supplier />
            </Route>
            <Route path="/vat">
              <Vat />
            </Route>
            <Route path="/wallets/:walletId">
              <Transactions />
            </Route>
            <Route path="/wallets">
              <Wallets />
            </Route>
            <Route path="/home">
              <Dashboard />
            </Route>
            <Route path="/token">
              <RedirectPage />
            </Route>
          </Switch>
        </main>
      </div>
    </LocalizationProvider>
  );
}

export default App;
