import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import FileSaver from "file-saver";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import { TableBody } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  margin: "10px 0 10px 0",
  maxWidth: 700,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledHeadTableRow = styled(TableRow)(({ theme }) => ({
  height: "10px",
}));

type Transaction = {
  account: string;
  description: string;
  debitAmount: number;
  creditAmount: number;
};

const Supplier = () => {
  const [supplier, setSupplier] = useState<Transaction[]>([]);

  const [from, setFrom] = useState(dayjs());
  const [to, setTo] = useState(dayjs());

  const handleSubmit = (e: any) => {
    e.preventDefault();

    fetch(
      `${
        process.env.REACT_APP_API_ROOT_URI
      }/queries/supplier-aggregation?from=${from.format(
        "YYYY-MM-DD"
      )}&to=${to.format("YYYY-MM-DD")}`,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("id_token"),
          "Content-Type": "application/json",
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSupplier(data.rows);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleDownload = async () => {
    const downloadUrl = `${process.env.REACT_APP_API_ROOT_URI}/queries/supplier-aggregation?from=${from}&to=${to}&csv=true`;
    const result = await fetch(downloadUrl, {
      headers: {
        Accept: "text/csv",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
    });
    const response = await result.json();
    const blob = new Blob([response.csv], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, `supplier-${from}--${to}`);
  };

  return (
    <Fragment>
      <h1>Supplier aggregation</h1>
      <Link to={"/home"} style={{ textDecoration: "none" }}>
        <Button>Home</Button>
      </Link>
      <form action="/supplier" method="GET" onSubmit={handleSubmit}>
        <DatePicker onChange={(e) => setFrom(e!)} defaultValue={dayjs()} />
        <DatePicker onChange={(e) => setTo(e!)} defaultValue={dayjs()} />
        <Button type="submit" variant="outlined">
          Submit
        </Button>
      </form>

      {supplier.length > 0 ? (
        <>
          <StyledTableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <StyledHeadTableRow>
                  <StyledTableCell>Account</StyledTableCell>
                  <StyledTableCell align="right">Description</StyledTableCell>
                  <StyledTableCell align="right">Debit</StyledTableCell>
                  <StyledTableCell align="right">Credit</StyledTableCell>
                </StyledHeadTableRow>
              </TableHead>
              <TableBody>
                {supplier.map((data) => (
                  <StyledTableRow key={data.account}>
                    <StyledTableCell component="th" scope="row">
                      {data.account}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {data.description}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {data.debitAmount}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {data.creditAmount}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <Button variant="contained" onClick={handleDownload}>
            Download spendings as CSV
          </Button>
        </>
      ) : (
        <div></div>
      )}
    </Fragment>
  );
};

export default Supplier;
