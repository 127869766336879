import React, {Fragment,} from "react";
import {Link, useHistory} from "react-router-dom";
import Button from '@mui/material/Button';
import dayjs from "dayjs";

const Dashboard = () => {

  const history = useHistory();
  const token = localStorage.getItem('id_token');

  if (token) {
    const jwtPayload = JSON.parse(window.atob(token.split('.')[1]))
    const expireTime = dayjs.unix(jwtPayload.exp);
    if (expireTime.isBefore(dayjs())) {
      localStorage.removeItem('id_token')
      history.replace('/')
    }
  } else {
    history.replace('/')
  }

  const logoutHandler = () => {
    localStorage.removeItem("id_token");
  }

  return (
    <Fragment>
      <div>
        <h1>Spending Service BackOffice</h1>
      </div>
      <div>
        <Link to={'/sales'} style={{textDecoration: 'none'}}>
          <Button>Sales aggregation bookkeeping instructions</Button>
        </Link>
      </div>
      <div>
        <Link to={'/supplier'} style={{textDecoration: 'none'}}>
          <Button>Supplier aggregation bookkeeping instructions</Button>
        </Link>
      </div>
      <div>
        <Link to={'/vat'} style={{textDecoration: 'none'}}>
          <Button>VAT aggregation</Button>
        </Link>
      </div>
      <div>
        <Link to={'/wallets'} style={{textDecoration: 'none'}}>
          <Button>Customer wallets</Button>
        </Link>
      </div>
      <div>
        <Link to={'/'} onClick={logoutHandler}>
          <Button>Logout</Button>
        </Link>
      </div>
    </Fragment>
  )
}

export default Dashboard;
