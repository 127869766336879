import React, {Fragment, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import dayjs from "dayjs";

type Transaction = {
  eventType: string;
  timestamp: number;
  amount: number;
  balance: number;
}

const Transactions = () => {

  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const {walletId} = useParams<{ walletId: string }>();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ROOT_URI}/queries/wallets/${walletId}`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Content-Type': 'application/json'
      })
    })
      .then((response) => response.json())
      .then((data) => {
        setTransactions(data.transactions);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [walletId]);

  return (
    <Fragment>
      <h1>Wallet transactions</h1>
      <Link to={'/home'} style={{textDecoration: 'none'}}>
        <Button>Home</Button>
      </Link>
      <table>
        <thead>
        <tr>
          <th>Type</th>
          <th>Timestamp</th>
          <th>Amount</th>
          <th>Balance</th>
        </tr>
        </thead>
        <tbody>
        {transactions.map((transaction, index) => {
          return (
            <tr key={index}>
              <td>{transaction.eventType}</td>
              <td>{dayjs(transaction.timestamp).format('YYYY-MM-DD HH:mm:DD')}</td>
              <td className="right">{(transaction.amount / 100).toFixed(2)}</td>
              <td className="right">{(transaction.balance / 100).toFixed(2)}</td>
            </tr>
          );
        })}
        </tbody>
      </table>
    </Fragment>
  );
}

export default Transactions;
