import React, {Fragment, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import dayjs from "dayjs";

type CustomerWallets = {
  walletId: string;
  foreignId: string;
  customerName: string;
  createdAt: number;
  balance: number;
}

const Wallets = () => {

  const [wallets, setWallets] = useState<CustomerWallets[]>([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ROOT_URI}/queries/wallets`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Content-Type': 'application/json'
      })
    })
      .then((response) => response.json())
      .then((data) => {
        setWallets(data.wallets);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, []);

  return (
    <Fragment>
      <h1>Customer wallets</h1>
      <Link to={'/home'} style={{ textDecoration: 'none' }}>
        <Button>Home</Button>
      </Link>
      <table>
        <thead>
        <tr>
          <th>Wallet ID</th>
          <th>ForeignId</th>
          <th>Customer name</th>
          <th>Created at</th>
          <th>Current balance</th>
        </tr>
        </thead>
        <tbody>
        {wallets.map((wallet) => {
          return (
            <tr key={wallet.walletId}>
              <td><Link style={{textDecoration: 'none'}} to={`/wallets/${wallet.walletId}`}>{wallet.walletId}</Link></td>
              <td>{wallet.foreignId}</td>
              <td>{wallet.customerName}</td>
              <td>{dayjs(wallet.createdAt).format('YYYY-MM-DD HH:mm:DD')}</td>
              <td className="right">{(wallet.balance / 100).toFixed(2)}</td>
            </tr>
          );
        })}
        </tbody>
      </table>
    </Fragment>
  );
}

export default Wallets;
