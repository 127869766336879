import React, {Fragment, useState} from 'react';
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import FileSaver from "file-saver";

type Transaction = {
  account: string;
  description: string;
  debitAmount: number;
  creditAmount: number;
}

const Sales = () => {

  const [sales, setSales] = useState<Transaction[]>([]);

  const [from, setFrom] = useState(dayjs().format('YYYY-MM-DD'));
  const [to, setTo] = useState(dayjs().format('YYYY-MM-DD'));

  const handleSubmit = (e: any) => {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_API_ROOT_URI}/queries/sales-aggregation?from=${from}&to=${to}`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Content-Type': 'application/json'
      })
    })
      .then((response) => response.json()
      )
      .then((data) => {
        setSales(data.rows);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }

  const handleDownload = async () => {
    const downloadUrl = `${process.env.REACT_APP_API_ROOT_URI}/queries/sales-aggregation?from=${from}&to=${to}&csv=true`;
    const result = await fetch(downloadUrl, {
      headers: {
        'Accept': 'text/csv',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
      }
    });
    const response = await result.json();
    const blob = new Blob([response.csv], {type: "text/csv;charset=utf-8"});
    FileSaver.saveAs(blob, `sales-${from}--${to}`);
  }

  return (
    <Fragment>
      <h1>Sales aggregation</h1>
      <Link to={'/home'} style={{textDecoration: 'none'}}>
        <Button>Home</Button>
      </Link>
      <form action="/sales" method="GET" onSubmit={handleSubmit}>
        <input type="date" onChange={(e) => setFrom(e.target.value)} value={from}/>
        <input type="date" onChange={(e) => setTo(e.target.value)} value={to}/>
        <button type='submit'>Submit</button>
      </form>
      {sales.length > 0 ?
        <div>
          <table>
            <thead>
            <tr>
              <th>Account</th>
              <th>Description</th>
              <th>Debit</th>
              <th>Credit</th>
            </tr>
            </thead>
            <tbody>
            {sales.map((transaction, index) => {
              return (
                <tr key={index}>
                  <td>{transaction.account}</td>
                  <td>{transaction.description}</td>
                  <td className="right">{(transaction.debitAmount / 100).toFixed(2)}</td>
                  <td className="right">{(transaction.creditAmount / 100).toFixed(2)}</td>
                </tr>
              );
            })}
            </tbody>
          </table>
          <div>
            <button onClick={handleDownload}>Download spendings as CSV</button>
          </div>
        </div>
        : <div/>}
    </Fragment>
  );
}

export default Sales;
