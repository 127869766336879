import React, {Fragment, useState} from 'react';
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import FileSaver from "file-saver";

type Transaction = {
  customerId: string;
  customerName: string;
  supplierId: string;
  supplierName: string;
  currency: string;
  netAmountLocalCurrency: number;
  vatAmountLocalCurrency: number;
}

const Vat = () => {

  const [sales, setSales] = useState<Transaction[]>([]);
  const [purchases, setPurchases] = useState<Transaction[]>([]);

  const [from, setFrom] = useState(dayjs().format('YYYY-MM-DD'));
  const [to, setTo] = useState(dayjs().format('YYYY-MM-DD'));
  const [countryCode, setCountryCode] = useState('NL');

  const handleSubmit = (e: any) => {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_API_ROOT_URI}/queries/vat-aggregation?from=${from}&to=${to}&countryCode=${countryCode}`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Content-Type': 'application/json'
      })
    })
      .then((response) => response.json()
      )
      .then((data) => {
        setSales(data.sales);
        setPurchases(data.purchases);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }

  const handleDownload = async () => {
    const downloadUrl = `${process.env.REACT_APP_API_ROOT_URI}/queries/vat-aggregation?from=${from}&to=${to}&countryCode=${countryCode}&csv=true`;
    const result = await fetch(downloadUrl, {
      headers: {
        'Accept': 'text/csv',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
      }
    });
    const response = await result.json();
    const blob = new Blob([response.csv], {type: "text/csv;charset=utf-8"});
    FileSaver.saveAs(blob, `vat-${from}--${to}`);
  }

  return (
    <Fragment>
      <h1>VAT aggregation</h1>
      <Link to={'/home'} style={{textDecoration: 'none'}}>
        <Button>Home</Button>
      </Link>
      <form action="/vat" method="GET" onSubmit={handleSubmit}>
        <input type="date" onChange={(e) => setFrom(e.target.value)} value={from}/>
        <input type="date" onChange={(e) => setTo(e.target.value)} value={to}/>
        <input type="text" onChange={(e) => setCountryCode(e.target.value)} value={countryCode}/>
        <button type='submit'>Submit</button>
      </form>
      {sales.length > 0 ?
        <div>
          <h3>Sales</h3>
          <table>
            <thead>
            <tr>
              <th>Customer ID</th>
              <th>Customer Name</th>
              <th>Currency</th>
              <th>NET amount, local currency</th>
              <th>VAT amount, local currency</th>
            </tr>
            </thead>
            <tbody>
            {sales.map((sale, index) => {
              return (
                <tr key={index}>
                  <td>{sale.customerId}</td>
                  <td>{sale.customerName}</td>
                  <td>{sale.currency}</td>
                  <td className="right">{(sale.netAmountLocalCurrency / 100).toFixed(2)}</td>
                  <td className="right">{(sale.vatAmountLocalCurrency / 100).toFixed(2)}</td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
        : <div/>}
      {purchases.length > 0 ?
        <div>
          <h3>Purchases</h3>
          <table>
            <thead>
            <tr>
              <th>Supplier ID</th>
              <th>Supplier Name</th>
              <th>Currency</th>
              <th>NET amount, local currency</th>
              <th>VAT amount, local currency</th>
            </tr>
            </thead>
            <tbody>
            {purchases.map((purchase, index) => {
              return (
                <tr key={index}>
                  <td>{purchase.supplierId}</td>
                  <td>{purchase.supplierName}</td>
                  <td>{purchase.currency}</td>
                  <td className="right">{(purchase.netAmountLocalCurrency / 100).toFixed(2)}</td>
                  <td className="right">{(purchase.vatAmountLocalCurrency / 100).toFixed(2)}</td>
                </tr>
              );
            })}
            </tbody>
          </table>
          <div>
            <button onClick={handleDownload}>Download spendings as CSV</button>
          </div>
        </div>
        : <div/>}
    </Fragment>
  );
}

export default Vat;
